
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter, usePagination, formatCPFToLGPD, formatDate, formatPhone } from "@/libs/utils";
import { Patient } from "@/store/patient/types";

const VPatientList = defineComponent({
  name: "VPatientList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de pacientes | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasFilters } = useFilter();

    const patients = computed(() => store.state.patient.list);
    const loading = reactive({ list: false });

    function getDropdownOptions(patient: Patient) {
      const items: DropdownItem[] = [
        {
          label: "Visualizar",
          icon: "fas fa-info-circle",
          to: { name: "patient-edit", params: { _id: String(patient.id) } },
        },
        {
          label: "Editar",
          icon: "fas fa-edit",
          to: { name: "patient-edit", params: { _id: String(patient.id) } },
        },
        {
          label: "Remover",
          icon: "fas fa-trash-alt",
          to: { name: "patient-delete", params: { _id: String(patient.id) } },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Pacientes",
        icon: "fas fa-hospital-user mr-1",
        to: { name: "patient-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "patient",
      setPage: "setPatientPaginationPage",
      updateList: getPatients,
    });

    async function getPatients() {
      loading.list = true;
      await store.dispatch("getPatients", { config: { useCompany: false } });
      loading.list = false;
    }

    if (hasFilters.value) router.push({ name: "patient-filter", query: route.query });
    else getPatients();

    return {
      ...{ patients, pagination, loading, breadcrumb, hasFilters },
      ...{ getDropdownOptions, handleUpdateCurrentPage, getPatients },
      ...{ formatCPFToLGPD, formatDate, formatPhone },
    };
  },
});

export default VPatientList;
